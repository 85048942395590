body {
  margin: 0;
  font-family: 'Noto Sans', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

butter-banner {
  padding: 10px;
  background-color: #ddd;
}

splash {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  border-radius: 1em;
  font-size: 1em;
  padding: 1em;
}

subp {
  display: block;
  font-size: 10px;
  text-align: center;
}

.center-content {
  margin-left: auto;
  margin-right: auto;
}

.footer-class {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height:20px;
  background-color: #ddd;
  color: #444;
  text-align: center;
  transition: height 2s;

}

.footer-class:hover {
  height: auto;
}

.butter-spread {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 15px;
  background-size: contain;
  background-repeat: repeat-x;
}

.badge-img {
  margin-left: 20px;
  width: 64px;
}
.badge-txt {
  font-size: 32px;
}

/* display: flex;
flex-direction: column;
align-items: center;
justify-content: center; */
