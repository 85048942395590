.nav-div{
  position: relative;
  padding-bottom: 4em;
}

a:link{
  color: black;
  text-decoration: none;
}
a:visited{
  color: black;
  text-decoration: none;
}
a:hover{
  color: black;
  text-decoration: none;
}
a:active{
  color: black;
  text-decoration: none;
}

.nav-bar{
  position: absolute;
  color: black;
  margin: auto;
}
.nav-dropdown{
  display: none;
}
.nav-link{
  float: left;
  margin: 5px;
}

.nav-link:hover{
  background-color: white;
  border-style: solid;
  padding: 3px;
  border-radius: 5px;
  border-color: #d4bd5f;
  transform: scale(1.1);
}

.nav-link:hover .nav-dropdown {
  display: block;
}

.nav-div ul{
  list-style: none;
  padding-inline-start: 0;
}

.nav-dropdown .nav-link{
  float: none;
}

.nav-active{
  background-color: #d4bd5f;
  border-radius: 5px;
  font-size: 1.2em;
}

.nav-inactive{
  color: lightgrey;
}