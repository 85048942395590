.cardBody{
  border-radius: 1em;
  font-size: 1em;
  padding: 1em;
  margin: 1em;
  background-image: 
  linear-gradient(
    to top, 
    #d4bd5f44, #ffd52644
  );
  max-width: 40em;
}